<i18n src="@i18n/drone/drone_model.th.yaml" locale="th" lang="yaml"></i18n>
<i18n src="@i18n/drone/drone_lot.th.yaml" locale="th" lang="yaml"></i18n>
<i18n src="@i18n/drone/drone.th.yaml" locale="th" lang="yaml"></i18n>
<i18n locale="th" lang="yaml" >
drone.warranty.remain : "เวลารับประกันเหลือ {day} วัน"
drone.warranty.end : "หมดอายุการรับประกันแล้ว"
</i18n>

<template>
	<a-card
		:bordered="false"
		:class="cardCssClasses"
		:loading="loading">

		<div class="mycard-content">
			<div class="mycard-header">
				<ImageLoader
					class="mycard-image"
					:src="model.image_url"
					border-type="round">
					<DroneIcon class="anticon" />
				</ImageLoader>
			</div>

			<ul class="mycard-details">
				<li>
					<span class="label">{{$t('drone.field.name')}}</span>
					<span class="value">{{drone.name}}</span>
				</li>
				<li>
					<span class="label">{{$t('drone.field.serial_no')}}</span>
					<span class="value">{{drone.serial_no}}</span>
				</li>
				<li>
					<span class="label">{{$t('drone.field.pixhawk_serial_no')}}</span>
					<span class="value" style="font-size:0.9em">{{drone.pixhawk_serial_no|emptyCheck}}</span>
				</li>

				<li v-if="$notEmpty(drone.warranty_end_date)">
					<span class="label">{{$t('drone.field.warranty_end_date')}}</span>
					<span class="value">
						{{drone.warranty_end_date | date}} <br />
					</span>
				</li>

				<li>
					<span class="label">{{$t('drone.field.firmware')}}</span>
					<span class="value">{{firmware.name | emptyCheck}}</span>
				</li>

				<li>
					<span class="label">{{$t('drone.field.latest_flight_time_sec')}}</span>
					<span class="value">
						<FlightTimeView
							:time-seconds="drone.latest_flight_time_sec"/>
					</span>
				</li>

				<li v-if="$notEmpty(drone.latest_maintenance_date)">
					<span class="label">{{$t('drone.field.latest_maintenance_date')}}</span>
					<span class="value"></span>
				</li>

				<li>
					<span class="label">{{$t('drone.field.status')}}</span>
					<span class="value">
						<DroneStatus :status="drone.drone_status"/>
					</span>
				</li>
				<hr />
				<li>
					<span class="label">{{$t('drone.field.policy')}}</span>
					<span class="value">
						{{$tenum('drone.policy',connect.policy)}}
					</span>
				</li>
				<li>
					<span class="label">{{$t('drone.field.lock_state')}}</span>
					<span class="value">
						{{$tenum('drone.lock_state',connect.lock_state)}}
					</span>
				</li>
				<hr />
				<li>
					<CustomerLiteCard
						v-if="owner && owner.id > 0"
						class="drone-owner-subcard"
						:customer="owner"
						:bordered="true"/>
				</li>
				<li>
					<a-card :class="lotCardCssClasses">
						<div class="mycard-content">
							<ul class="mycard-details">
								<li>
									<span class="label">{{$t('drone_model.field.name')}}</span>
									<span class="value">
										<my-router-link name="drone_model/view" :param-value="model.id" auth-action="read" auth-module="droneModel"
											new-window>
											{{model.name}}
										</my-router-link>
									</span>
								</li>
								<li>
									<span class="label">{{$t('drone_model.field.display_name')}}</span>
									<span class="value">
										<my-router-link name="drone_model/view" :param-value="model.id" auth-action="read" auth-module="droneModel"
											new-window>
											{{model.display_name}}
										</my-router-link>
									</span>
								</li>
								<li>
									<span class="label">{{$t('drone_model.field.drone_type')}}</span>
									<span class="value">{{$tenum('drone_type',model.drone_type)}}</span>
								</li>
								<li>
									<span class="label">{{$t('drone_lot.field.lot_name')}}</span>
									<span class="value">
										<my-router-link name="drone_lot/view" :param-value="lot.id"
											new-window>
											{{lot.lot_name}}
										</my-router-link>
									</span>
								</li>
								<li>
									<span class="label">{{$t('drone_lot.field.manufacture_date')}}</span>
									<span class="value">{{lot.manufacture_date | date}}</span>
								</li>
							</ul>
						</div>
					</a-card>
				</li>
			</ul>
		</div>
	</a-card>
</template>

<script>
import DroneIcon from "@components/icon/DroneIcon.vue"
import ImageLoader from "@components/common/ImageLoader.vue"
import FlightTimeView from "@components/common/FlightTimeView.vue"
import CustomerLiteCard from "@components/customer/CustomerLiteCard.vue"
import DroneStatus from "@components/drone/DroneStatus.vue"


export default {
	components : {
		ImageLoader  , FlightTimeView, CustomerLiteCard,DroneStatus,DroneIcon
	} ,
	props : {
		loading : {
			type : Boolean,
			default : false,
		} ,
		model : {
			type : null,
			default : () => []
		} ,
		lot : {
			type : null,
			default : () => []
		} ,
		drone : {
			type : null,
			default : () => []
		} ,
		owner : {
			type : null,
			default : () => []
		} ,
		firmware : {
			type : null,
			default : () => []
		} ,
		connect : {
			type : null,
			default : () => []
		}
	} ,

	computed : {
		cardCssClasses() {
			return [
				"mycard", "mycard-with-details", "drone-card" ,
				{
					'mycard-inactive' : this.drone.drone_status === 'decommission'
				}
			]
		} ,
		lotCardCssClasses() {
			return [
				"drone-lot-subcard","mycard", "mycard-with-details",
				{
					"mycard-inactive" : this.model.status === 'inactive'
				}
			]
		}
	} ,
}
</script>

<style lang="less" scoped>
.drone-card.mycard::v-deep {
	font-size : 0.95em;
	> .ant-card-body {
		padding : 16px;
	}
	.mycard-details > li {
		margin-bottom : 4px;
	}
}
.drone-lot-subcard.mycard::v-deep ,
.drone-owner-subcard::v-deep {
	margin-top : 16px;
	.ant-card-body {
		padding : 6px 12px;
	}

}
.mycard-with-details ul.mycard-details > li .label {
	min-width : 100px;
}
</style>
